import {
    Button,
    CheckBox,
    Control,
    Icon,
    Label,
    NumberBox,
    Panel,
    Select,
    TextArea,
} from "@core/components/alt-ui/controls";
import {
    IGood,
    IGoodInventory,
    IGoodInventoryCreateDto,
    IGoodInventoryInfo,
    IGoodInventoryUpdateDto,
    IGoodUseCase,
    IStore,
    Locale,
} from "@lib";
import { Table, TableColumn, TableFooter } from "@core/components/alt-ui/table";
import { debounce } from "@/utils/delayed-operation";
import { GoodUseCase } from "@core/usecases/store/good.usecase";
import { Uuid } from "@/utils/uuid";
import { AltMessage } from "@/utils/plugins/alt-message";
import { Modal } from "@core/components/alt-ui/modal";
import router from "@/router/router";
import { Formatter } from "@/utils/formatter";

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface GoodInventoryV1ModalContext {
    userId: string;
    stores: IStore[];
    companyId: string;
    storeId: string;
    goodInventory?: IGoodInventory;
    message: AltMessage;
}

export type GoodInventoryV1TableItem = {
    goodRef: IGood;
    storeCost: number;
    storeQuantity: number;
    inventoryQuantity: number;
};

/**
 * Модалка для старой версии инвентаризации.
 * @deprecated Использовать `GoodInventoryModal`. Данная модалка используется для поддержки старой версии инвентаризации.
 */
export class GoodInventoryV1Modal extends Modal<GoodInventoryV1ModalContext> {
    private cbStore!: Select<IStore>;
    private cbGood!: Select<IGood>;
    private chbDiff!: CheckBox;
    private tblGoodsTable!: Table<GoodInventoryV1TableItem>;
    private taComment!: TextArea;

    private btnCancel!: Button;
    private btnSave!: Button;
    private btnComplete!: Button;
    private pnlFooter!: Panel;

    private context!: GoodInventoryV1ModalContext;
    public onCreate: ((dto: IGoodInventoryCreateDto) => Promise<IGoodInventory | null>) | null = null;
    public onUpdate: ((id: string, dto: IGoodInventoryUpdateDto) => Promise<IGoodInventory | null>) | null = null;
    public onResume: ((id: string) => Promise<IGoodInventory | null>) | null = null;

    private GoodUseCase: IGoodUseCase = new GoodUseCase();

    public constructor(id: string) {
        super(id, "");

        this.size = "lg";
        this.initializeControls();
    }

    public get controls(): Control[] {
        return [this.cbStore, this.cbGood, this.chbDiff, this.tblGoodsTable, this.taComment, this.pnlFooter];
    }

    public show(context: GoodInventoryV1ModalContext): Promise<void> {
        this.context = context;
        this.initializeControls();

        if (this.context.goodInventory) {
            this.populateControls(this.context.goodInventory);
        }

        return super.show();
    }

    private initializeControls(): void {
        this.title = "Инвентаризация";

        this.cbStore = new Select<IStore>();
        this.cbGood = new Select();
        this.chbDiff = new CheckBox();
        this.tblGoodsTable = new Table<GoodInventoryV1TableItem>();
        this.taComment = new TextArea();
        this.btnCancel = new Button();
        this.btnSave = new Button();
        this.btnComplete = new Button();
        this.pnlFooter = new Panel();

        //

        this.cbStore.id = "good-inventory-v1.store";
        this.cbStore.label = "Склад";
        this.cbStore.items = this.context?.stores ?? [];
        this.cbStore.textField = ac => ac.info.name;
        this.cbStore.descriptionField = ac => ac.info.description;
        this.cbStore.selectedItem =
            this.cbStore.items.find(o => o.id === this.context?.storeId) ??
            (this.cbStore.items.length > 0 ? this.cbStore.items[0] : null);

        //

        this.cbGood.id = "good-inventory-v1.good-search";
        this.cbGood.label = "Введите название товара";
        this.cbGood.items = [];
        this.cbGood.textField = e => e.info.name;
        this.cbGood.descriptionField = e => e.info.description;

        const debouncedSearch = debounce(async (search: string) => {
            search = search.trim();

            // начинать поиск от 2 символов
            if (search.length < 2) {
                this.cbGood.items = [];
                return;
            }

            const goods = await this.GoodUseCase.select(this.context.companyId, {
                search,
                limit: 5,
                offset: 0,
                filter: {
                    quantity: { gt: 0 },
                    store: { in: [this.cbStore.selectedItem?.id || this.context.storeId] },
                },
            });

            this.cbGood.items = goods.data;
        }, 700);

        this.cbGood.search = async (search, loading) => {
            if (loading) {
                loading(true);
            }
            await debouncedSearch(search);
            if (loading) {
                loading(false);
            }
        };

        this.cbGood.addChangedHandler((control, info) => {
            const good = info.item;

            if (good) {
                const exists = this.tblGoodsTable.items.find(i => i.goodRef.id === good.id);

                if (exists) {
                    // обновляем цену и количество
                    exists.storeCost = good.info.cost;
                    exists.storeQuantity = good.info.quantity;
                    exists.inventoryQuantity += 1;
                } else {
                    this.tblGoodsTable.items.push({
                        goodRef: good,
                        storeCost: good.info.cost,
                        storeQuantity: good.info.quantity,
                        inventoryQuantity: 1,
                    });
                }
            }

            this.cbGood.items = [];
            this.cbGood.selectedItem = null;
        });

        //

        this.chbDiff.id = "good-inventory-v1.good-diff";
        this.chbDiff.text = "Показать только отклонения";
        this.chbDiff.class = "mt-0.5";
        this.chbDiff.visible = false;
        this.chbDiff.addValueChangedHandler((s, e) => {
            if (!this.context.goodInventory) {
                return;
            }

            this.tblGoodsTable.items = this.getTableItems(this.context.goodInventory);

            if (e.value) {
                this.tblGoodsTable.items = this.tblGoodsTable.items.filter(
                    i => i.inventoryQuantity - i.storeQuantity !== 0,
                );
            }
        });

        //

        this.tblGoodsTable.id = "good-inventory-v1.good-table";
        this.tblGoodsTable.class = "mt-2";
        this.tblGoodsTable.columns = this.getTableColumns(false);

        //

        this.taComment.id = "good-inventory-v1.comment";
        this.taComment.label = "Комментарий";

        //

        this.btnCancel.id = "good-inventory-v1.cancel";
        this.btnCancel.text = "Закрыть";
        this.btnCancel.class = "mr-auto";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave.id = "good-inventory-v1.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.class = "mr-0.75";
        this.btnSave.variant = "outline-primary";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.btnComplete.id = "good-inventory-v1.complete";
        this.btnComplete.text = "Завершить";
        this.btnComplete.addClickHandler(this.clickComplete.bind(this));

        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
        this.pnlFooter.addControl(this.btnComplete);
    }

    private populateControls(goodInventory: IGoodInventory): void {
        this.title += ` #${goodInventory.number}`;
        this.cbStore.selectedItem = this.cbStore.items.find(o => o.id === goodInventory.store) ?? null;
        this.tblGoodsTable.items = this.getTableItems(goodInventory);
        this.tblGoodsTable.columns = this.getTableColumns(goodInventory.done);
        this.tblGoodsTable.footer = this.getTableFooter();
        this.taComment.text = goodInventory.comment ?? "";

        this.btnComplete.visible = true;
        if (goodInventory.done) {
            this.title += ` - ${Formatter.datetime(goodInventory.doneAt)}`;

            this.btnComplete.text = "Возобновить";
            this.btnSave.visible = false;
            this.taComment.disabled = true;
            this.cbStore.disabled = true;
            this.cbGood.visible = false;
            this.chbDiff.visible = true;
        }
    }

    private getTableItems(goodInventory: IGoodInventory): GoodInventoryV1TableItem[] {
        return goodInventory.goods.map(g => {
            return {
                goodRef: g.goodRef as IGood,
                storeCost: g.storeCost,
                storeQuantity: g.storeQuantity,
                inventoryQuantity: g.inventoryQuantity,
            } as GoodInventoryV1TableItem;
        });
    }

    private getTableFooter(): TableFooter<GoodInventoryV1TableItem>[] {
        const locale = this.cbStore.selectedItem?.info.locale ?? Locale.RU;

        return [
            {
                title: "Излишек:",
                cell: items => {
                    const value = items.reduce((sum, item) => {
                        const delta = item.inventoryQuantity - item.storeQuantity;
                        sum += delta > 0 ? item.storeCost * delta : 0;
                        return sum;
                    }, 0);

                    return Formatter.money(value, {
                        locale: locale,
                        grouping: true,
                        showNullFraction: false,
                    });
                },
            },
            {
                title: "Недостача:",
                cell: items => {
                    const value = items.reduce((sum, item) => {
                        const delta = item.inventoryQuantity - item.storeQuantity;
                        sum += delta < 0 ? item.storeCost * -delta : 0;
                        return sum;
                    }, 0);

                    return Formatter.money(value, {
                        locale: locale,
                        grouping: true,
                        showNullFraction: false,
                    });
                },
            },
        ];
    }

    private getTableColumns(readonly: boolean): TableColumn<GoodInventoryV1TableItem>[] {
        const columns: TableColumn<GoodInventoryV1TableItem>[] = [
            {
                title: "Наименование",
                width: 50,
                classHeader: "text-left text-xs",
                classCell: "text-left",
                cell: item => {
                    const name = new Label();
                    name.class = "text-base";
                    name.text = item.goodRef.info.name;

                    const icon = new Icon();
                    icon.class = "cursor-pointer text-secondary hover:text-dark w-1 ml-0.75";
                    icon.icon = "ExternalLinkIcon";
                    icon.addClickHandler((s, e) => {
                        const routeData = router.resolve({ name: "stores", query: { id: item.goodRef.id } });
                        window.open(routeData.href, "_blank");
                    });

                    const group = new Panel();
                    group.class = "flex";
                    group.addControls([name, icon]);
                    return group;
                },
            },
            {
                title: "Факт",
                classHeader: "text-xs",
                width: 20,
                cell: item => {
                    if (readonly) {
                        const lbl = new Label();
                        lbl.id = Uuid.new();
                        lbl.class = "text-base";
                        lbl.text = item.inventoryQuantity.toString();
                        return lbl;
                    }

                    const number = new NumberBox();
                    number.id = Uuid.new();
                    number.min = 0;
                    number.max = Number.MAX_VALUE;
                    number.value = item.inventoryQuantity;
                    number.disabled = readonly;
                    number.addValueChangedHandler((s, e) => {
                        // обновляем цену и количество
                        item.storeCost = item.goodRef.info.cost;
                        item.storeQuantity = item.goodRef.info.quantity;
                        item.inventoryQuantity = e.value ?? 0;
                    });

                    return number;
                },
            },
            {
                title: "Учёт",
                classHeader: "text-xs",
                width: 20,
                cell: item => item.storeQuantity.toString(),
            },
            {
                title: "Отклонение",
                classHeader: "text-xs",
                width: 20,
                cell: item => {
                    const delta = item.inventoryQuantity - item.storeQuantity;
                    const color = delta > 0 ? "text-success" : delta < 0 ? "text-danger" : "";

                    const lbl = new Label();
                    lbl.id = Uuid.new();
                    lbl.class = `text-base ${color}`;
                    lbl.text = delta.toString();
                    return lbl;
                },
            },
            {
                title: "Цена",
                classHeader: "text-xs",
                width: 20,
                cell: item =>
                    Formatter.money(item.storeCost, {
                        locale: this.cbStore.selectedItem?.info.locale ?? Locale.RU,
                        grouping: true,
                        showNullFraction: false,
                    }),
            },
        ];

        if (!readonly) {
            columns.unshift({
                width: 1,
                cell: (item, index) => {
                    const iconDelete = new Icon();
                    iconDelete.icon = "Trash2Icon";
                    iconDelete.class = "cursor-pointer hover:text-danger";
                    iconDelete.addClickHandler(async (s, e) => {
                        const confirm = await this.context.message.confirm(
                            `Вы уверены, что хотите удалить товар "${item.goodRef.info.name}"?`,
                            "Удаление товара",
                            { acceptText: "Удалить" },
                        );

                        if (confirm) {
                            this.tblGoodsTable.items = this.tblGoodsTable.items.filter((_, i) => i !== index);
                        }
                    });
                    return iconDelete;
                },
            });
        }

        return columns;
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        if (!this.tblGoodsTable.items.length) {
            return;
        }

        const inventory = this.context.goodInventory ? await this.update() : await this.create();

        if (inventory) {
            this.context.goodInventory = inventory;
            this.initializeControls();
            this.populateControls(inventory);
            // this.hide(result);
        }
    }

    private async clickComplete(sender: any, e: any): Promise<void> {
        if (!this.tblGoodsTable.items.length) {
            return;
        }

        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const inventory = this.context.goodInventory?.done ? await this.resume() : await this.complete();

        if (inventory) {
            this.context.goodInventory = inventory;
            this.initializeControls();
            this.populateControls(inventory);
            // this.hide(result);
        }
    }

    private getGoodsFromTable(): IGoodInventoryInfo[] {
        return this.tblGoodsTable.items.map(item => {
            const good: IGoodInventoryInfo = {
                goodId: item.goodRef.id,
                storeCost: item.storeCost,
                storeQuantity: item.storeQuantity,
                inventoryQuantity: item.inventoryQuantity,
            };

            return good;
        });
    }

    private async create(): Promise<IGoodInventory | null> {
        if (!this.onCreate) {
            return null;
        }

        if (!this.cbStore.selectedItem) {
            return null;
        }

        const goods = this.getGoodsFromTable();

        const dto: IGoodInventoryCreateDto = {
            goods: goods as IGoodInventoryInfo[],
            storeId: this.cbStore.selectedItem.id,
            employeeId: this.context.userId,
            comment: this.taComment.text,
        };

        return await this.onCreate(dto);
    }

    private async update(): Promise<IGoodInventory | null> {
        if (!this.onUpdate) {
            return null;
        }

        if (!this.context.goodInventory) {
            return null;
        }

        const goods = this.getGoodsFromTable();

        const dto: IGoodInventoryUpdateDto = {
            goods: goods as IGoodInventoryInfo[],
            comment: this.taComment.text,
        };

        return await this.onUpdate(this.context.goodInventory.id, dto);
    }

    private async complete(): Promise<IGoodInventory | null> {
        const goods = this.getGoodsFromTable();

        if (this.context.goodInventory) {
            if (!this.onUpdate) {
                return null;
            }

            const dto: IGoodInventoryUpdateDto = {
                goods: goods as IGoodInventoryInfo[],
                comment: this.taComment.text,
                done: true,
            };

            return await this.onUpdate(this.context.goodInventory.id, dto);
        }

        if (!this.onCreate) {
            return null;
        }

        if (!this.cbStore.selectedItem) {
            return null;
        }

        const dto: IGoodInventoryCreateDto = {
            goods: goods as IGoodInventoryInfo[],
            storeId: this.cbStore.selectedItem.id,
            employeeId: this.context.userId,
            comment: this.taComment.text,
            done: true,
        };

        return await this.onCreate(dto);
    }

    private async resume(): Promise<IGoodInventory | null> {
        if (!this.onResume) {
            return null;
        }

        if (!this.context.goodInventory) {
            return null;
        }

        return await this.onResume(this.context.goodInventory.id);
    }
}
